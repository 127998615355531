<template>
  <v-container>
    <v-row class="pt-10">
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-img v-if="$route.name === 'CancelPayment'" src="../assets/logos/autosense-logo.png" alt="Logo" />
        <v-img v-if="$route.name === 'CancelPaymentChargeOn'" src="../assets/logos/helion-logo-dark.svg" alt="Logo" />
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-row class="pb-13">
      <v-col cols="1"></v-col>
      <v-col>
        <v-card elevation="10" class="rounded-card pt-3 pb-3">
          <v-card-title v-if="loading"> Loading station details... </v-card-title>
          <v-card-title v-else>{{ this.station_info.name }}</v-card-title>
          <v-card-subtitle v-if="!loading" class="text-left">
            {{ this.station_info.address }}, {{ this.station_info.city }}
          </v-card-subtitle>
          <v-card-text v-if="!loading" class="text-left">
            <v-row>
              <v-col class="font-weight-bold">EVSE ID: </v-col>
              <v-col>
                <v-img v-if="this.plugImage !== ''" :src="this.plugImage" style="width: 30px" />
                {{ this.evse_id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="todaysOpeningTime().length > 0" class="font-weight-bold">Opening Times:</v-col>
              <v-col v-if="todaysOpeningTime().length > 0">
                <v-row v-for="(times, index) in todaysOpeningTime()" :key="index">
                  <v-col>
                    {{ times.from }} -
                    {{ times.to }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-for="(price, index) in evsePrice()" :key="index" class="text-left">
              <v-col>
                <v-row>
                  <v-col class="font-weight-bold" cols="6"> Prices: </v-col>
                </v-row>
                <v-row v-if="price.price !== 0">
                  <v-col> Consumption Fee: {{ price.currency }} {{ price.price }} / kWh </v-col>
                </v-row>
                <v-row v-if="price.fair_use_price === 0 && price.time_price !== 0">
                  <v-col> Time Fee: {{ price.currency }} {{ price.time_price }} / min </v-col>
                </v-row>
                <v-row v-if="price.session_price !== 0">
                  <v-col> Session Fee: {{ price.currency }} {{ price.session_price }} </v-col>
                </v-row>
                <v-row v-if="price.fair_use_price > 0 && price.time_price !== 0">
                  <v-col>
                    Fair Use Fee: After {{ price.fair_use_price }} minutes of parking an additional price of
                    {{ price.currency }} {{ price.time_price }} / min will apply.
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="font-weight-bold">Payment Cancelled</v-col>
            </v-row>
          </v-card-text>

          <v-row class="buttons ma-auto">
            <v-col class="d-flex justify-center">
              <v-btn @click="goHome()">Try Again</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  created() {
    this.station_info = JSON.parse(sessionStorage.getItem('stationInfo'));
    this.evse_id = JSON.parse(sessionStorage.getItem('request')).evse_id;
    this.plugImage = sessionStorage.getItem('plugImage');
    this.loading = false;
  },
  data() {
    return {
      loading: true,
      station_info: {},
    };
  },
  methods: {
    todaysOpeningTime() {
      return JSON.parse(sessionStorage.getItem('openingTime'));
    },
    evsePrice() {
      return JSON.parse(sessionStorage.getItem('evsePrice'));
    },
    goHome() {
      const pastRequest = JSON.parse(sessionStorage.getItem('request'));
      this.$router.push(this.$route.name === 'CancelPayment' ? 'Charge' : 'ChargeChargeOn', {
        query: {
          station_id: pastRequest.station_id,
          show_support_page: pastRequest.show_support_page,
          evse_id: pastRequest.evse_id,
          tenant_id: pastRequest.tenant_id,
          shortcode: pastRequest.shortcode,
        },
      });
    },
  },
};
</script>

<style scoped>
.buttons button {
  background-image: linear-gradient(135deg, rgb(55, 150, 131), rgb(92, 219, 149));
  border-radius: 20px;
  color: rgb(255, 255, 255);
}

.rounded-card {
  border-radius: 20px;
}
</style>
