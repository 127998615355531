import axios from 'axios';
import config from '../config';

// Handles all API calls with jsonwebtoken authentication
export default async function (method, url, data, language) {
  return await axios.request({
    baseURL: config.api.base_url,
    url,
    method,
    data,
    timeout: 10000, // 10 seconds timeout
    headers: {
      'api-key': config.api.token,
      'Accept-Language': language,
    },
  });
}
