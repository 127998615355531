<template>
  <v-container>
    <v-row class="pt-10">
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-img v-if="$route.name === 'WhileCharging'" src="../assets/logos/autosense-logo.png" alt="Logo" />
        <v-img v-if="$route.name === 'WhileChargingChargeOn'" src="../assets/logos/helion-logo-dark.svg" alt="Logo" />
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-row class="pb-13">
      <v-col cols="1"></v-col>
      <v-col>
        <v-card elevation="10" class="rounded-card pt-3 pb-3">
          <v-card-title v-if="loading"> Loading station details... </v-card-title>
          <v-card-title v-else>{{ this.station_info.name }}</v-card-title>
          <v-card-subtitle v-if="!loading" class="text-left">
            {{ this.station_info.address }}, {{ this.station_info.city }}
          </v-card-subtitle>
          <v-card-text v-if="!loading" class="text-left">
            <v-row>
              <v-col class="font-weight-bold">EVSE ID: </v-col>
              <v-col>
                <v-img v-if="this.plugImage !== ''" :src="this.plugImage" style="width: 30px" />
                {{ this.evse_id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="todaysOpeningTime().length > 0" class="font-weight-bold">Opening Times:</v-col>
              <v-col v-if="todaysOpeningTime().length > 0">
                <v-row v-for="(times, index) in todaysOpeningTime()" :key="index">
                  <v-col>
                    {{ times.from }} -
                    {{ times.to }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-for="(price, index) in evsePrice()" :key="index" class="text-left">
              <v-col>
                <v-row>
                  <v-col class="font-weight-bold" cols="6"> Prices: </v-col>
                </v-row>
                <v-row v-if="price.price !== 0">
                  <v-col> Consumption Fee: {{ price.currency }} {{ price.price }} / kWh </v-col>
                </v-row>
                <v-row v-if="price.fair_use_price === 0 && price.time_price !== 0">
                  <v-col> Time Fee: {{ price.currency }} {{ price.time_price }} / min </v-col>
                </v-row>
                <v-row v-if="price.session_price !== 0">
                  <v-col> Session Fee: {{ price.currency }} {{ price.session_price }} </v-col>
                </v-row>
                <v-row v-if="price.fair_use_price > 0 && price.time_price !== 0">
                  <v-col>
                    Fair Use Fee: After {{ price.fair_use_price }} minutes of parking an additional price of
                    {{ price.currency }} {{ price.time_price }} / min will apply.
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="!loadingCharging">
              <v-col>
                <v-row>
                  <v-col class="font-weight-bold">Charging {{ this.getStatusMessage() }}</v-col>
                </v-row>
                <v-row>
                  <v-col> Time elapsed: {{ this.duration }}</v-col>
                </v-row>
                <v-row class="buttons">
                  <v-col class="d-flex justify-center">
                    <v-btn @click="stopCharging">Stop Charging</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>

    <v-btn v-if="this.showSupportPage && !loading" @click="dialog = true" class="help-button">Help</v-btn>

    <v-dialog v-if="!loading" v-model="dialog" max-width="300">
      <v-card>
        <v-card-title class="headline">{{ this.station_info.operator_name }} Support</v-card-title>
        <v-card-text class="text-center">
          To contact the operator, call
          <a :href="'tel:' + this.station_info.phone_number">{{ this.station_info.phone_number }} </a>.
          <br />
          <br />
          This Support Number has been provided by {{ this.station_info.operator_name }}. Alternatively check the
          charging station for assistance instructions.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert v-model="showAlert" type="error" dismissible class="alert-message">
      {{ alertMessage }}
    </v-alert>
  </v-container>
</template>

<script>
import executeAPI from '../services/execute-api';
export default {
  created() {
    this.station_info = JSON.parse(sessionStorage.getItem('stationInfo'));
    this.showSupportPage = JSON.parse(sessionStorage.getItem('request')).showSupportPage;
    this.tenant = JSON.parse(sessionStorage.getItem('request')).tenant;
    this.evse_id = JSON.parse(sessionStorage.getItem('request')).evse_id;
    this.plugImage = sessionStorage.getItem('plugImage');
    this.loading = false;
    this.sessionId = sessionStorage.getItem('sessionId');
  },
  data() {
    return {
      showAlert: false,
      alertMessage: '',
      dialog: false,
      loading: true,
      loadingCharging: true,
      station_info: {},
      status: null,
      duration: '',
      timeout: null,
    };
  },
  methods: {
    showError(message) {
      this.alertMessage = message;
      this.showAlert = true;

      // Clear any existing timeout to prevent multiple timeouts running
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.showAlert = false;
        this.timeout = null;
      }, 5000);
    },
    todaysOpeningTime() {
      return JSON.parse(sessionStorage.getItem('openingTime'));
    },
    evsePrice() {
      return JSON.parse(sessionStorage.getItem('evsePrice'));
    },
    getStatusMessage() {
      const statusMessage = {
        PREPARING: 'starting ...',
        INPROGRESS: 'in progress',
        NOTINPROGRESS: 'finished',
        UNPAID: 'to be paid',
      };
      return statusMessage[this.status.status];
    },
    async fetchStatus() {
      // For testing purpose
      // this.status = {
      //   status: 'INPROGRESS',
      //   created_at: new Date('2024-04-29T12:00:00Z'),
      // };
      // this.timer = setInterval(() => {
      //   this.updateDuration();
      // }, 1000);

      this.loadingCharging = true;
      try {
        const response = await executeAPI('GET', `/api/v1/charging/${this.sessionId}/status`);
        this.status = response.data;
        if (this.status.status === 'NOTINPROGRESS') {
          this.loadingCharging = false;
          setTimeout(() => {
            this.$router.push({ name: this.tenant === 'AUTOSENSE' ? 'Summary' : 'SummaryChargeOn' });
          }, 2000);
        } else if (this.status.status === 'INPROGRESS' && !this.timer)
          this.timer = setInterval(() => {
            this.updateDuration();
          }, 1000);
      } catch (error) {
        this.showError('Failed to fetch charging status. Please refresh the page to attempt again.');
      }
      this.loadingCharging = false;
    },
    updateDuration() {
      const startTime = new Date(this.status.created_at);
      const now = new Date();
      const diff = now - startTime;

      const hours = Math.floor(diff / 3600000); // 1 hour = 3600000 milliseconds
      const minutes = Math.floor((diff % 3600000) / 60000); // 1 minute = 60000 milliseconds
      const seconds = Math.floor((diff % 60000) / 1000); // 1 second = 1000 milliseconds

      this.duration = `${hours}h ${minutes}m ${seconds}s`;
    },
    async stopCharging() {
      try {
        await executeAPI('POST', `/api/v1/charging/${this.sessionId}/stop`);
        await this.$router.push({ name: this.tenant === 'AUTOSENSE' ? 'Summary' : 'SummaryChargeOn' });
      } catch {
        this.showError('Failed to stop charging. Please try again or stop the charging from the charging station.');
      }
    },
  },
  mounted() {
    this.fetchStatus();
    this.intervalId = setInterval(() => {
      this.fetchStatus();
    }, 30000);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style scoped>
.buttons button {
  background-image: linear-gradient(135deg, rgb(55, 150, 131), rgb(92, 219, 149));
  border-radius: 20px;
  color: rgb(255, 255, 255);
}

.help-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.rounded-card {
  border-radius: 20px;
}

.alert-message {
  position: fixed;
  bottom: 20px;
  left: 10px;
  right: 10px;
}
</style>
