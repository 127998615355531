<template>
  <v-container>
    <v-row class="pt-10">
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-img v-if="$route.name === 'AfterPayment'" src="../assets/logos/autosense-logo.png" alt="Logo" />
        <v-img v-if="$route.name === 'AfterPaymentChargeOn'" src="../assets/logos/helion-logo-dark.svg" alt="Logo" />
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-row class="pb-13">
      <v-col cols="1"></v-col>
      <v-col>
        <v-card elevation="10" class="rounded-card pt-3 pb-3">
          <v-card-title v-if="loading"> Loading station details... </v-card-title>
          <v-card-title v-else>{{ this.station_info.name }}</v-card-title>
          <v-card-subtitle v-if="!loading" class="text-left">
            {{ this.station_info.address }}, {{ this.station_info.city }}
          </v-card-subtitle>
          <v-card-text v-if="!loading" class="text-left">
            <v-row>
              <v-col class="font-weight-bold">EVSE ID: </v-col>
              <v-col>
                <v-img v-if="this.plugImage !== ''" :src="this.plugImage" style="width: 30px" />
                {{ this.evse_id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="todaysOpeningTime().length > 0" class="font-weight-bold">Opening Times:</v-col>
              <v-col v-if="todaysOpeningTime().length > 0">
                <v-row v-for="(times, index) in todaysOpeningTime()" :key="index">
                  <v-col>
                    {{ times.from }} -
                    {{ times.to }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-for="(price, index) in evsePrice()" :key="index" class="text-left">
              <v-col>
                <v-row>
                  <v-col class="font-weight-bold" cols="6"> Prices: </v-col>
                </v-row>
                <v-row v-if="price.price !== 0">
                  <v-col> Consumption Fee: {{ price.currency }} {{ price.price }} / kWh </v-col>
                </v-row>
                <v-row v-if="price.fair_use_price === 0 && price.time_price !== 0">
                  <v-col> Time Fee: {{ price.currency }} {{ price.time_price }} / min </v-col>
                </v-row>
                <v-row v-if="price.session_price !== 0">
                  <v-col> Session Fee: {{ price.currency }} {{ price.session_price }} </v-col>
                </v-row>
                <v-row v-if="price.fair_use_price > 0 && price.time_price !== 0">
                  <v-col>
                    Fair Use Fee: After {{ price.fair_use_price }} minutes of parking an additional price of
                    {{ price.currency }} {{ price.time_price }} / min will apply.
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-row v-if="loadingCharge">
            <v-col>
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-else class="buttons ma-auto">
            <v-col class="d-flex justify-center">
              <v-btn @click="startCharging()">Start Charging</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>

    <v-btn v-if="this.showSupportPage && !loading" @click="dialog = true" class="help-button">Help</v-btn>

    <v-dialog v-if="!loading" v-model="dialog" max-width="300">
      <v-card>
        <v-card-title class="headline">{{ this.station_info.operator_name }} Support</v-card-title>
        <v-card-text class="text-center">
          To contact the operator, call
          <a :href="'tel:' + this.station_info.phone_number">{{ this.station_info.phone_number }} </a>.
          <br />
          <br />
          This Support Number has been provided by {{ this.station_info.operator_name }}. Alternatively check the
          charging station for assistance instructions.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn @click="cancelPage" class="cancel-button">Cancel</v-btn>

    <v-alert v-model="showAlert" type="error" dismissible class="alert-message">
      {{ alertMessage }}
    </v-alert>
  </v-container>
</template>

<script>
import executeAPI from '../services/execute-api';
export default {
  created() {
    this.station_info = JSON.parse(sessionStorage.getItem('stationInfo'));
    this.showSupportPage = JSON.parse(sessionStorage.getItem('request')).showSupportPage;
    this.evse_id = JSON.parse(sessionStorage.getItem('request')).evse_id;
    this.plugImage = sessionStorage.getItem('plugImage');
    this.loading = false;
    this.updateRouteParams();
    if (
      this.checkField(this.sessionId) ||
      this.checkField(this.maskedCC) ||
      this.checkField(this.aliasCC) ||
      this.checkField(this.cardType) ||
      this.checkField(this.expiryMonth) ||
      this.checkField(this.expiryYear)
    ) {
      // Theoretically this shouldn't happen
      this.$router.push({
        name: 'ErrorPage',
        params: {
          errorMessage: 'Invalid Payment Details',
          errorDetails: 'The entered payment method is missing parameters.',
        },
      });
    } else {
      this.postStatus();
    }
  },
  data() {
    return {
      showAlert: false,
      alertMessage: '',
      dialog: false,
      loading: true,
      station_info: {},
      sessionId: '',
      maskedCC: '',
      aliasCC: '',
      cardType: '',
      expiryMonth: '',
      expiryYear: '',
      timeout: null,
      loadingCharge: true,
    };
  },
  methods: {
    showError(message) {
      this.alertMessage = message;
      this.showAlert = true;

      // Clear any existing timeout to prevent multiple timeouts running
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.showAlert = false;
        this.timeout = null;
      }, 5000);
    },
    todaysOpeningTime() {
      return JSON.parse(sessionStorage.getItem('openingTime'));
    },
    evsePrice() {
      return JSON.parse(sessionStorage.getItem('evsePrice'));
    },
    checkField(field) {
      return field === null || field === undefined || field === '';
    },
    updateRouteParams() {
      this.sessionId = this.$route.params.sessionId;
      this.storageSessionId = sessionStorage.getItem('sessionId');
      if (this.sessionId !== this.storageSessionId) {
        sessionStorage.removeItem('sessionId');
        this.$router.push({
          name: 'ErrorPage',
          params: {
            errorMessage: 'Invalid Session ID',
            errorDetails: 'Invalid session id. Please start the process anew.',
          },
        });
      }
      sessionStorage.setItem('sessionId', this.sessionId);

      this.maskedCC = this.$route.query.maskedCC;
      this.aliasCC = this.$route.query.aliasCC;
      this.cardType = this.$route.query.pmethod;
      this.expiryMonth = this.$route.query.expm;
      this.expiryYear = this.$route.query.expy;
    },
    async postStatus() {
      try {
        await executeAPI('POST', `/api/v1/paymentInfo/${this.sessionId}`, {
          credit_card_number: this.maskedCC,
          alias_cc: this.aliasCC,
          card_type: this.cardType,
          expiry_month: this.expiryMonth,
          expiry_year: this.expiryYear,
        });
        this.loadingCharge = false;
      } catch {
        this.showError('Failed to post payment details. Trying again in 5 seconds.');
        setTimeout(() => {
          this.postStatus();
        }, 5000);
      }
    },
    async startCharging() {
      try {
        await executeAPI('POST', `/api/v1/charging/${this.sessionId}/start`);
        this.$router.push({ name: this.tenant === 'AUTOSENSE' ? 'WhileCharging' : 'WhileChargingChargeOn' });
      } catch {
        this.showError('Failed to start charging. Please try again.');
      }
    },
    cancelPage() {
      sessionStorage.removeItem('stationInfo');
      sessionStorage.removeItem('sessionId');
      this.$router.push({ name: this.tenant === 'AUTOSENSE' ? 'Charge' : 'ChargeChargeOn' });
    },
  },
  computed: {
    tenant() {
      if (this.$route.name === 'AfterPayment') {
        return 'AUTOSENSE';
      } else if (this.$route.name === 'AfterPaymentChargeOn') {
        return 'CHARGEON';
      }
      return 'default';
    },
  },
};
</script>

<style scoped>
.buttons button {
  background-image: linear-gradient(135deg, rgb(55, 150, 131), rgb(92, 219, 149));
  border-radius: 20px;
  color: rgb(255, 255, 255);
}

.help-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.cancel-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.rounded-card {
  border-radius: 20px;
}

.alert-message {
  position: fixed;
  bottom: 20px;
  left: 10px;
  right: 10px;
}
</style>
