<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h2>{{ errorMessage }}</h2>
          </v-card-title>
          <v-card-text>
            <p>{{ errorDetails }}</p>
          </v-card-text>
          <v-row class="buttons ma-auto">
            <v-col class="d-flex justify-center">
              <v-btn @click="goHome()">Try Again</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: 'An error occurred',
    },
    errorDetails: {
      type: String,
      default: 'Please try again later.',
    },
  },
  methods: {
    goHome() {
      const pastRequest = JSON.parse(sessionStorage.getItem('request'));
      this.$router.push(pastRequest.tenant === 'AUTOSENSE' ? 'Charge' : 'ChargeChargeOn', {
        query: {
          station_id: pastRequest.station_id,
          show_support_page: pastRequest.show_support_page,
          evse_id: pastRequest.evse_id,
          tenant_id: pastRequest.tenant_id,
          shortcode: pastRequest.shortcode,
        },
      });
    },
  },
};
</script>

<style scoped>
.buttons button {
  background-image: linear-gradient(135deg, rgb(55, 150, 131), rgb(92, 219, 149));
  border-radius: 20px;
  color: rgb(255, 255, 255);
}
</style>
