import Vue from 'vue';
import VueRouter from 'vue-router';
import Charge from '../views/Charge.vue';
import ErrorPage from '../views/ErrorPage.vue';
import AfterPayment from '../views/AfterPayment.vue';
import CancelPayment from '../views/CancelPayment.vue';
import ErrorPayment from '../views/ErrorPayment.vue';
import Summary from '../views/Summary.vue';
import WhileCharging from '../views/WhileCharging.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/charge',
    name: 'Charge',
    component: Charge,
    query: {
      tenant_id: null,
      station_id: null,
      evse_id: null,
      shortcode: null,
      show_support_page: null,
    },
  },
  {
    path: '/charge-charge-on',
    name: 'ChargeChargeOn',
    component: Charge,
    query: {
      tenant_id: null,
      station_id: null,
      evse_id: null,
      shortcode: null,
      show_support_page: null,
    },
  },
  {
    path: '/charging/:sessionId/success',
    name: 'AfterPayment',
    component: AfterPayment,
    query: {
      maskedCC: null,
      aliasCC: null,
      pmethod: null,
      expm: null,
      expy: null,
    },
  },
  {
    path: '/charging-charge-on/:sessionId/success',
    name: 'AfterPaymentChargeOn',
    component: AfterPayment,
    query: {
      maskedCC: null,
      aliasCC: null,
      pmethod: null,
      expm: null,
      expy: null,
    },
  },
  {
    path: '/charging/:sessionId/cancel',
    name: 'CancelPayment',
    component: CancelPayment,
  },
  {
    path: '/charging-charge-on/:sessionId/cancel',
    name: 'CancelPaymentChargeOn',
    component: CancelPayment,
  },
  {
    path: '/charging/:sessionId/error',
    name: 'ErrorPayment',
    component: ErrorPayment,
  },
  {
    path: '/charging-charge-on/:sessionId/error',
    name: 'ErrorPaymentChargeOn',
    component: ErrorPayment,
  },
  {
    path: '/charging/:sessionId/summary',
    name: 'Summary',
    component: Summary,
  },
  {
    path: '/charging-charge-on/:sessionId/summary',
    name: 'SummaryChargeOn',
    component: Summary,
  },
  {
    path: '/charging/:sessionId/whileCharging',
    name: 'WhileCharging',
    component: WhileCharging,
  },
  {
    path: '/charging-charge-on/:sessionId/whileCharging',
    name: 'WhileChargingChargeOn',
    component: WhileCharging,
  },
  {
    path: '/error/:errorMessage/:errorDetails?',
    name: 'ErrorPage',
    component: ErrorPage,
    props: true,
  },
  {
    path: '*',
    redirect: () => {
      return {
        name: 'ErrorPage',
        params: { errorMessage: '404 Not Found', errorDetails: 'The requested page does not exist.' },
      };
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
