<template>
  <v-container>
    <v-row class="pt-10 pb-2">
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-img v-if="$route.name === 'Charge'" src="../assets/logos/autosense-logo.png" alt="Logo" />
        <v-img v-if="$route.name === 'ChargeChargeOn'" src="../assets/logos/helion-logo-dark.svg" alt="Logo" />
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-row class="pb-13">
      <v-col cols="1"></v-col>
      <v-col>
        <v-card elevation="10" class="rounded-card pt-3 pb-3">
          <v-card-title v-if="loading"> Loading station details... </v-card-title>
          <v-card-title v-else>{{ this.station_info.name }}</v-card-title>
          <v-card-subtitle v-if="!loading" class="text-left">
            {{ this.station_info.address }}, {{ this.station_info.city }}
          </v-card-subtitle>
          <v-card-text v-if="!loading" class="text-left">
            <v-row>
              <v-col class="font-weight-bold">EVSE ID: </v-col>
              <v-col>
                <v-img v-if="this.plugImage !== ''" :src="this.plugImage" style="width: 30px" />
                {{ this.evse_id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="todaysOpeningTime().length > 0" class="font-weight-bold">Opening Times:</v-col>
              <v-col v-if="todaysOpeningTime().length > 0">
                <v-row v-for="(times, index) in todaysOpeningTime()" :key="index">
                  <v-col>
                    {{ times.from }} -
                    {{ times.to }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-for="(price, index) in evsePrice()" :key="index" class="text-left">
              <v-col>
                <v-row>
                  <v-col class="font-weight-bold" cols="6"> Prices: </v-col>
                </v-row>
                <v-row v-if="price.price !== 0">
                  <v-col> Consumption Fee: {{ price.currency }} {{ price.price }} / kWh </v-col>
                </v-row>
                <v-row v-if="price.fair_use_price === 0 && price.time_price !== 0">
                  <v-col> Time Fee: {{ price.currency }} {{ price.time_price }} / min </v-col>
                </v-row>
                <v-row v-if="price.session_price !== 0">
                  <v-col> Session Fee: {{ price.currency }} {{ price.session_price }} </v-col>
                </v-row>
                <v-row v-if="price.fair_use_price > 0 && price.time_price !== 0">
                  <v-col>
                    Fair Use Fee: After {{ price.fair_use_price }} minutes of parking an additional price of
                    {{ price.currency }} {{ price.time_price }} / min will apply.
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-form class="form-fields">
                  <v-row>
                    <v-col>
                      <v-text-field label="First Name" v-model="firstName" />
                    </v-col>
                    <v-col>
                      <v-text-field label="Last Name" v-model="lastName" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Email address"
                        placeholder="johndoe@gmail.com"
                        type="email"
                        v-model="email"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox v-model="termsAccepted">
                        <template #label>
                          <span style="padding-left: 5px">
                            I accept the
                            <a
                              :href="
                                isChargeOnTenant()
                                  ? 'https://chargeon.helion.ch/de/agb.html'
                                  : 'https://www.autosense.ch/en/agb'
                              "
                              target="_blank"
                              @click.stop
                            >
                              terms and conditions
                            </a>
                            and
                            <a
                              :href="
                                isChargeOnTenant()
                                  ? 'https://chargeon.helion.ch/de/datenschutz.html'
                                  : 'https://www.autosense.ch/en/datenschutz'
                              "
                              target="_blank"
                              @click.stop
                            >
                              privacy policy
                            </a>
                          </span>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row class="buttons my-auto d-flex justify-center">
                    <v-btn v-for="card of creditCards()" :key="card.id" @click="submitForm(card.id)" class="ma-1">{{
                      card.name
                    }}</v-btn>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>

    <v-btn v-if="this.showSupportPage && !loading" @click="dialog = true" class="help-button">Help</v-btn>

    <v-dialog v-if="!loading" v-model="dialog" max-width="300">
      <v-card>
        <v-card-title class="headline">{{ this.station_info.operator_name }} Support</v-card-title>
        <v-card-text class="text-center">
          To contact the operator, call
          <a :href="'tel:' + this.station_info.phone_number">{{ this.station_info.phone_number }} </a>.
          <br />
          <br />
          This Support Number has been provided by {{ this.station_info.operator_name }}. Alternatively check the
          charging station for assistance instructions.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert v-model="showAlert" type="error" dismissible class="alert-message">
      {{ alertMessage }}
    </v-alert>
  </v-container>
</template>

<script>
import executeAPI from '../services/execute-api';
export default {
  created() {
    this.updateRouteParams();
    if (
      this.checkField(this.tenant_id) ||
      this.checkField(this.station_id) ||
      this.checkField(this.evse_id) ||
      this.checkField(this.shortcode)
    ) {
      this.$router.push({
        name: 'ErrorPage',
        params: {
          errorMessage: 'Invalid Station Details',
          errorDetails: 'We are sorry for the inconvenience, please try again later.',
        },
      });
    } else {
      this.loadData();
    }
  },
  data() {
    return {
      showAlert: false,
      alertMessage: '',
      dialog: false,
      loading: true,
      firstName: '',
      lastName: '',
      email: '',
      termsAccepted: false,
      tenant_id: this.$route.query.tenant_id,
      station_id: this.$route.query.station_id,
      evse_id: this.$route.query.evse_id,
      shortcode: this.$route.query.shortcode,
      showSupportPage: false,
      tenant: '',
      timeout: null,
    };
  },
  methods: {
    isChargeOnTenant() {
      return this.tenant === 'CHARGEON';
    },

    creditCards() {
      if (this.isChargeOnTenant()) {
        return [
          { id: 'VIS', name: 'Visa' },
          { id: 'ECA', name: 'Mastercard' },
        ];
      }
      return [
        { id: 'VIS', name: 'Visa' },
        { id: 'ECA', name: 'Mastercard' },
        { id: 'AMX', name: 'Amex' },
      ];
    },

    showError(message) {
      this.alertMessage = message;
      this.showAlert = true;

      // Clear any existing timeout to prevent multiple timeouts running
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.showAlert = false;
        this.timeout = null;
      }, 5000);
    },
    updateRouteParams() {
      this.tenant_id = this.$route.query.tenant_id;
      this.station_id = this.$route.query.station_id;
      this.evse_id = this.$route.query.evse_id;
      this.shortcode = this.$route.query.shortcode;
      this.showSupportPage = this.$route.query.show_support_page === 'true';
      this.tenant = this.$route.name === 'Charge' ? 'AUTOSENSE' : 'CHARGEON';
    },
    todaysOpeningTime() {
      const today = new Date().getDay();
      if (this.station_info.opening_times === undefined) {
        sessionStorage.setItem('openingTime', JSON.stringify([]));
        return [];
      }
      const openingTime = this.station_info.opening_times.find((day) => day.day === today);
      sessionStorage.setItem('openingTime', JSON.stringify(openingTime.times));
      return openingTime.times;
    },
    evsePrice() {
      for (let i = 0; i < this.station_info.products_points.length; i++) {
        for (let j = 0; j < this.station_info.products_points[i].fuelPoints.length; j++) {
          if (this.station_info.products_points[i].fuelPoints[j].id.toLowerCase() === this.evse_id.toLowerCase()) {
            sessionStorage.setItem(
              'evsePrice',
              JSON.stringify([
                this.station_info.prices.find(
                  (price) => price.product_id === this.station_info.products_points[i].power_type,
                ),
              ]),
            );
            return [
              this.station_info.prices.find(
                (price) => price.product_id === this.station_info.products_points[i].power_type,
              ),
            ];
          }
        }
      }
    },
    checkField(field) {
      return field === null || field === undefined || field === '';
    },
    async loadData() {
      try {
        const response = await executeAPI('GET', `/api/v1/station/${this.station_id}?tenant=${this.tenant}`);
        this.station_info = response.data;
        this.plugType = '';

        for (let i = 0; i < this.station_info.products_points.length; i++) {
          for (let j = 0; j < this.station_info.products_points[i].fuelPoints.length; j++) {
            if (this.station_info.products_points[i].fuelPoints[j].id.toLowerCase() === this.evse_id.toLowerCase()) {
              this.plugType = this.station_info.products_points[i].productId;
              break;
            }
          }
        }
        switch (this.plugType) {
          case 'IEC_62196_T1_COMBO':
          case 'IEC_62196_T2_COMBO':
            this.plugImage = '/images/charge_plug_ccs.png';
            break;
          case 'IEC_62196_T1':
          case 'IEC_60309_2_single_16':
          case 'IEC_60309_2_three_16':
          case 'IEC_60309_2_three_32':
          case 'IEC_60309_2_three_64':
          case 'IEC_62196_T3A':
          case 'IEC_62196_T3C':
            this.plugImage = '/images/charge_plug_typ_1.png';
            break;
          case 'IEC_62196_T2':
            this.plugImage = '/images/charge_plug_typ_2.png';
            break;
          case 'DOMESTIC_B':
          case 'DOMESTIC_D':
          case 'DOMESTIC_E':
          case 'DOMESTIC_F':
          case 'DOMESTIC_G':
          case 'DOMESTIC_H':
          case 'DOMESTIC_I':
          case 'DOMESTIC_J':
          case 'DOMESTIC_K':
          case 'DOMESTIC_L':
            this.plugImage = '/images/charge_plug_typ_j.png';
            break;
          case 'DOMESTIC_A':
          case 'DOMESTIC_C':
            this.plugImage = '/images/charge_plug_wall_outlet.png';
            break;
          case 'CHADEMO':
            this.plugImage = '/images/charge_plug_chademo.png';
            break;
          default:
            this.plugImage = '';
        }

        sessionStorage.setItem('stationInfo', JSON.stringify(this.station_info));
        sessionStorage.setItem('plugImage', this.plugImage);
        this.loading = false;
      } catch (error) {
        if (error.code === 'ECONNABORTED') {
          this.showError('Failed to load station details. Please refresh the page.');
        } else {
          this.loading = false;
          this.$router.push({
            name: 'ErrorPage',
            params: {
              errorMessage: 'Failed to load station details',
              errorDetails: 'We are sorry for the inconvenience, please try again later.',
            },
          });
        }
      }
    },
    validateForm() {
      if (this.checkField(this.firstName)) {
        this.showError('Please provide your first name.');
        return false;
      }
      if (this.checkField(this.lastName)) {
        this.showError('Please provide your last name.');
        return false;
      }
      if (this.checkField(this.email)) {
        this.showError('Please provide your email address.');
        return false;
      }
      if (!this.termsAccepted) {
        this.showError('Please accept the terms and conditions.');
        return false;
      }
      return true;
    },
    async submitForm(buttonName) {
      if (!this.validateForm()) {
        return;
      }

      try {
        const response = await executeAPI('POST', '/api/v1/initiate_session', {
          tenant_id: this.tenant_id,
          station_id: this.station_id,
          evse_id: this.evse_id,
          shortcode: this.shortcode,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          terms_accepted: this.termsAccepted,
          card_type: buttonName,
          tenant: this.tenant,
          show_support_page: this.showSupportPage,
        });
        sessionStorage.setItem('sessionId', response.data.session_id);
        sessionStorage.setItem(
          'request',
          JSON.stringify({
            tenant_id: this.tenant_id,
            station_id: this.station_id,
            evse_id: this.evse_id,
            shortcode: this.shortcode,
            show_support_page: this.showSupportPage,
            tenant: this.tenant,
          }),
        );
        window.location.href = response.data.payment_url;
      } catch (error) {
        this.showError('Failed to initiate session. Please try again later.');
      }
    },
  },
};
</script>

<style scoped>
.buttons button {
  background-image: linear-gradient(135deg, rgb(55, 150, 131), rgb(92, 219, 149));
  border-radius: 20px;
  color: rgb(255, 255, 255);
}

.help-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.rounded-card {
  border-radius: 20px;
}

.alert-message {
  position: fixed;
  bottom: 20px;
  left: 10px;
  right: 10px;
}
</style>
